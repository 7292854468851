import request from '@/utils/request'

const licencesApi = {
  // get licences info
  licences: '/api/v1/rest/licences',
  findByKeyword: '/api/v1/rest/licences/search/findByKeyword',
  findByHouses_Id: '/api/v1/rest/licences/search/findByHouseId'
}

/**
 * list
 * @param parameter { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function licences (parameter) {
  return request({
    url: licencesApi.licences + `?page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * add
 * @param parameter { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function addLicences (parameter) {
  return request({
    url: licencesApi.licences,
    method: 'post',
    data: parameter
  })
}

/**
 * edit
 * @param parameter { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editLicences (parameter) {
  return request({
    url: licencesApi.licences + `/${parameter.id}`,
    method: 'patch',
    data: parameter
  })
}

/**
 * del
 * @param parameter { id: '' }
 * @returns {*}
 */
export function delLicences (parameter) {
  return request({
    url: licencesApi.licences + `/${parameter.id}`,
    method: 'delete'
  })
}

/**
 * put
 * @param parameter { id: '' }
 * @returns {*}
 */
export function putLicencesMedia (licencesId, data) {
  return request({
    url: licencesApi.licences + '/' + licencesId + '/img',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

/**
 * search
 * @param parameter { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findLicences (parameter) {
  return request({
    url: licencesApi.findByKeyword + `?keyword=${parameter.keyword}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * search
 * @param parameter { id: '' }
 * @returns {*}
 */
export function findByIdLicences (parameter) {
  return request({
    url: licencesApi.licences + `/${parameter.id}`,
    method: 'get'
  })
}

/**
 * licences
 * @param parameter { id: '', name: '' }
 * @returns {*}
 */
export function inLicences (parameter) {
  return request({
    url: licencesApi.licences + `/${parameter.id}/${parameter.name}`,
    method: 'get'
  })
}

/**
 * licences
 * @param parameter { id: '', name: '' }
 * @returns {*}
 */
export function addInLicences (parameter) {
  return request({
    url: licencesApi.licences + `/${parameter.id}/${parameter.name}`,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * findByHouses_Id
 * @param parameter { id: '', page: '', size: '', sort: '' }
 * @returns {*}
 */
export function findByHousesIdLicences (parameter) {
  return request({
    url: licencesApi.findByHouses_Id + `?houseId=${parameter.id}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}
