<template>
  <a-card :bordered="false">
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新增</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete">删除</a-button>
    </div>
    <a-modal
      title="刪除"
      :visible="del.visible"
      :confirm-loading="del.confirmLoading"
      @ok="handleDeleteOk"
      @cancel="handleDeleteCancel"
    >
      <p>{{ del.ModalText }}</p>
    </a-modal>

    <s-table
      ref="table"
      size="default"
      :rowKey="record => record.id"
      :columns="columns"
      :data="LicencesData"
      :alert="true"
      :rowSelection="rowSelection"
      showPagination="auto"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="status" slot-scope="text">
        <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
      </span>
      <span slot-scope="text">
        <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
      </span>

      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
        </template>
      </span>
    </s-table>

    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      :media="media"
      @cancel="handleCancel"
      @ok="handleOk"
      @emitMedia="getMedia"
    />
    <step-by-step-modal ref="modal" @ok="handleOk" />
  </a-card>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { addLicences, delLicences, editLicences, findByHousesIdLicences } from '@/api/licences'
import { getDate } from '@/utils/util'
import { uploadOssBatch } from '@/utils/upload'

import StepByStepModal from './modules/StepByStepModal'
import CreateForm from './modules/CreatePresell'

import { THUMBNAIL_MAX } from '@/common/const'

const columns = [
  {
    title: '预售证',
    dataIndex: 'code'
  },
  {
    title: '绑定楼栋',
    dataIndex: 'building'
  },
  {
    title: '发证时间',
    dataIndex: 'date',
    sorter: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'default',
    text: '关闭'
  },
  1: {
    status: 'processing',
    text: '运行中'
  },
  2: {
    status: 'success',
    text: '已上线'
  },
  3: {
    status: 'error',
    text: '异常'
  }
}

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    StepByStepModal
  },
  data() {
    this.columns = columns
    return {
      houseId: '',
      houseLink: '',
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      media: [],
      // 批量删除 modal
      del: {
        ModalText: '您要删除这些项目吗',
        visible: false,
        confirmLoading: false
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // 加载数据方法 必须为 Promise 对象
      LicencesData: parameter => {
        console.log(parameter, 'parameter')
        let sorterStr = ''
        if (parameter.sortOrder === 'descend') {
          sorterStr = `${parameter.sortField},desc`
        } else if (parameter.sortOrder === 'ascend') {
          sorterStr = `${parameter.sortField}`
        } else {
          sorterStr = `createTime,desc`
        }
        const requestParameters = {
          id: this.houseId,
          page: parameter.pageNo - 1,
          size: parameter.pageSize,
          sort: sorterStr
        }
        console.log(requestParameters, 'requestParameters')
        return findByHousesIdLicences(requestParameters)
          .then(res => {
            const result = {
              data: res._embedded.licences,
              pageNo: res.page.number,
              pageSize: res.page.size,
              totalCount: res.page.totalElements,
              totalPages: res.page.totalPages
            }
            return result
          })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    host() {
      return this.$store.state.oss.host
    },
    serial() {
      return this.$store.state.env.serial
    }
  },
  methods: {
    getMedia(data) {
      console.log(data, 'Licences data')
      this.media = data
    },
    handleAdd() {
      this.mdl = null
      this.media = []
      this.visible = true
    },
    handleEdit(record) {
      this.visible = true
      if (record.img) {
        console.log(record, 'resv')
        const img = record.img
        const path = `${this.host}${img}`
        const fileList = [
          {
            uid: '513216515151515',
            url: `${path}${THUMBNAIL_MAX}`,
            name: path.slice(path.lastIndexOf('/') + 1),
            status: 'done',
            path,
            sort: 0
          }
        ]
        console.log(fileList)
        this.media = fileList
      } else {
        this.media = []
      }
      this.mdl = { ...record }
    },
    // 批量删除
    handleDelete() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.del.visible = true
      }
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项'
      this.del.confirmLoading = true
      setTimeout(() => {
        this.del.confirmLoading = false
        this.del.ModalText = '您要删除这些项目吗'
        this.confirmLoading = true
        if (this.del.visible !== false) {
          this.selectedRows.forEach((item, index) => {
            const requestParameters = {
              id: item.id
            }
            delLicences(requestParameters)
              .then(res => {
                // 刷新表格
                this.$refs.table.refresh()
                this.$message.success('删除成功', 2)
                this.del.visible = false
                this.confirmLoading = false
                this.selectedRows.splice(0, this.selectedRows.length)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          })
        } else {
          this.del.visible = false
          this.confirmLoading = false
        }
      }, 2000)
    },
    handleDeleteCancel(e) {
      
      this.del.visible = false
    },
    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          const params = { ...values }
          const { media, ...requestParameters } = params
          requestParameters.date = getDate(requestParameters.date)
          if (this.mdl) {
            requestParameters.id = this.mdl.id

            console.log(!this.media, '!this.media')
            if (this.media.length === 0) {
              requestParameters.img = ''
              editLicences(requestParameters)
                .then(res => {
                  const msg = '编辑成功'
                  this.visible = false
                  this.confirmLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
            } else {
              this.fileListUpload(this.media, requestParameters, 'edit', requestParameters.id)
            }
          } else {
            requestParameters.house = this.houseLink
            console.log(this.mdl, 'this.mdl add')
            if (this.media.length === 0) {
              requestParameters.img = ''
              addLicences(requestParameters)
                .then(res => {
                  const msg = '新建成功'
                  this.visible = false
                  this.confirmLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
            } else {
              this.fileListUpload(this.media, requestParameters, 'add')
            }
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    fileListUpload(fileList, params, title, id = '') {
      const form = this.$refs.createModal.form
      const mediaList = this.media.map(item => {
        if (item.originFileObj === undefined) {
          return item
        } else {
          return item.originFileObj
        }
      })
      uploadOssBatch(mediaList, '/Licences/img/' + id).then(mediaRes => {
        if (mediaRes) {
          console.log(mediaRes, 'mediaRes')
          const urls = mediaRes
            .map(item => {
              if (item.path.indexOf('https') !== -1) {
                console.log(item.path.indexOf('https'), 'item.path.indexOf()');
                return '/Licences/img' + item.path.slice(item.path.indexOf('img/') + 3)
              } else {
                return item.path
              }
            })
            .join('\n')
          console.log(urls, 'urls')
          params.img = urls
          switch (title) {
            case 'add':
              addLicences(params)
                .then(res => {
                  const msg = '新建成功'
                  this.visible = false
                  this.confirmLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
              break
            case 'edit':
              editLicences(params)
                .then(res => {
                  const msg = '新建成功'
                  this.visible = false
                  this.confirmLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
              break
          }
        }
      })
    },
    handleCancel() {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
      this.mdl = null
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
