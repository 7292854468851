var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"预售许可证","width":800,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":function () {
      _vm.$emit('ok')
    },"cancel":function () {
      _vm.$emit('cancel')
    }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-form-item',{attrs:{"label":"许可证号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            {
              rules: [
                { required: true, message: '请输入许可证号！' },
                { max: 20, message: '长度不能大于20' }
              ]
            }
          ]),expression:"[\n            'code',\n            {\n              rules: [\n                { required: true, message: '请输入许可证号！' },\n                { max: 20, message: '长度不能大于20' }\n              ]\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"发证时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['date', { rules: [{ required: true, message: '请选择发证时间' }] }]),expression:"['date', { rules: [{ required: true, message: '请选择发证时间' }] }]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"绑定楼栋"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'building',
            {
              rules: [
                { required: true, message: '请输入绑定楼栋！' },
                { max: 20, message: '长度不能大于20' }
              ]
            }
          ]),expression:"[\n            'building',\n            {\n              rules: [\n                { required: true, message: '请输入绑定楼栋！' },\n                { max: 20, message: '长度不能大于20' }\n              ]\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"上传图片","extra":"支持扩展名：.jpg, .jpeg, .png","labelCol":{ lg: { span: 7 }, sm: { span: 15 } },"wrapperCol":{ lg: { span: 12 }, sm: { span: 17 } }}},[_c('uploadOss',{attrs:{"media":_vm.media,"visible":_vm.previewVisible,"maxLength":1},on:{"emitFiles":_vm.getFileList}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }